import React from 'react';
import { Link, graphql } from 'gatsby';
import BrandLogo from '../components/brandlogo';

import Layout from '../components/layout';
import SEO from '../components/seo';
import '../styles/index.scss';

const svgStyle = {
    style: {
        width: 100,
        maxHeight: 60
    }
};

const logoMapping = {
    ettan: <BrandLogo.Ettan {...svgStyle} />,
    granit: <BrandLogo.Granit {...svgStyle} />,
    general: <BrandLogo.General {...svgStyle} />,
    'göteborgs rapé': <BrandLogo.Goteborgs {...svgStyle} />,
    kaliber: <BrandLogo.Kaliber {...svgStyle} />,
    kronan: <BrandLogo.Kronan {...svgStyle} />,
    catch: <BrandLogo.Catch {...svgStyle} />,
    grov: <BrandLogo.Grov {...svgStyle} />,
    jakobssons: <BrandLogo.Jakobssons {...svgStyle} />,
    knekt: <BrandLogo.Knekt {...svgStyle} />,
    knox: <BrandLogo.Knox {...svgStyle} />,
    lundgrens: <BrandLogo.Lundgrens {...svgStyle} />,
    ld: <BrandLogo.LD {...svgStyle} />,
    mocca: <BrandLogo.Mocca {...svgStyle} />,
    'röda lacket': <BrandLogo.RodaLacket {...svgStyle} />,
    skruf: <BrandLogo.Skruf {...svgStyle} />,
}

const IndexPage = ({ data, location: { pathname } }) => {
    const { allSnus } = data;

    const brands = allSnus.nodes.reduce((acc, nxt) => {
        const { brand } = nxt;
        if (!acc[brand]) {
            acc[brand] = logoMapping[brand];
        };
        return acc;
    }, {});

    return (
        <Layout isIndex>
            <SEO
                title="Jämför snuspriser online | billigprilla.se"
                keywords={[...Object.keys(brands), 'billigt', 'snus', 'billigprilla.se']}
                pathname={pathname}
            />
            <div className="text-center indexpage">
                <div className="text-center my-8">
                    <h1 className="text-5xl sm:text-6xl font-semibold">billigprilla.se</h1>
                    <h2 className="text-xl font-semibold">jämför snuspriser online</h2>
                </div>
                <div>
                    <h3 className="mb-3 font-medium">Välj din favoritprilla:</h3>
                    <ul
                        className="flex flex-wrap justify-between max-w-3xl m-auto p-5"
                    >
                        {Object.keys(brands).map(brand => {
                            const className = brand.replace(' ', '-').replace('ö', 'o').replace('é', 'e');
                            return (
                                <li
                                    key={brand}
                                    className={`rounded h-20 mb-3 border-black border w-1/2 md:w-1/4 -mx-2 md:-mx-4 bg-white ${className}`}
                                    style={{ transition: 'all .2s ease' }}
                                >
                                    <Link
                                        to={`/${brand}`}
                                        className="h-full flex items-center justify-center li-link"
                                        tabIndex="0"
                                        title={brand}
                                    >
                                        {brands[brand]}
                                    </Link>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </Layout>
    );
};

export const query = graphql`
    query {
        allSnus {
            nodes {
                brand
            }
        }
    }
`;

export default IndexPage;
